<template>
  <div>
    <div v-if="props.message" class="py-4">
      <div v-if="messageType === 'gesoAndGem'">
        ダイヤとゲソコインを使用して<br>
        購入しますか？
      </div>
      <div v-else-if="messageType === 'geso'">
        <template v-if="!props.cost.purchasedOnlyFlg">
          ダイヤが足りません。<br>
        </template>
        ゲソコインを使用して購入しますか？
      </div>
      <div v-else-if="messageType === 'gem'">
        ダイヤを使用して購入しますか？
      </div>
      <div v-else-if="messageType === 'lessGeso'">
        <template v-if="!props.cost.purchasedOnlyFlg">
          無償ダイヤが足りません。<br>
        </template>
        ゲソコインを使用して購入しますか？
      </div>
      <div v-else>
        <div v-if="props.cost.purchasedOnlyFlg">
          有償ダイヤが足りません。<br>
        </div>
        <div v-else>
          ダイヤが足りません。<br>
        </div>
        ダイヤを購入しますか？
      </div>
    </div>
    <div v-if="isCanBuy">
      <div v-for="(it, k) of shopCostChangeDataList" :key="k" class="mb-3">
        <CommonChangeValue :title="it.name" :before="it.before" :after="it.before + it.value" />
      </div>
    </div>
    <div v-if="!isCanBuy && props.forceDisplay">
      <div v-for="(it, k) of shopCostChangeDataList" :key="k" class="mb-3">
        <CommonChangeValue :title="it.name" :before="it.before" :after="it.before + it.value < 0 ? 0 : it.before + it.value" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getShopCostChangeData, isCanBuyCost, ShopChangeData } from '~/libs/page/shop/type';
import { ShopBuyCost, useWallet } from '~/composables/useWallet';
import { isAnimatePlatform } from '~/libs/platform';

interface Props {
  cost: ShopBuyCost,
  message?: boolean,
  forceDisplay?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  message: true,
  forceDisplay: false
});

type MessageType = 'gesoAndGem' | 'geso' | 'gem' | 'less' | 'lessGeso';
const messageType = ref<MessageType>('gem');
const isCanBuy = ref<boolean>(false);
/**
 * 初期化処理
 */
const init = (data: Props) => {
  calcChangeParams(data);
  if (data.message) {
    messageType.value = getMessageType(data);
  }
  isCanBuy.value = isCanBuyCost({
    cost: data.cost,
    wallet: useWallet().wallet.value,
    animateWallet: useAnimateWallet().wallet.value,
  });
};
onMounted(() => {
  init(props);
});
watch(props, data => init(data));

const shopCostChangeDataList = ref<ShopChangeData[]>([]);
/**
 * データの変化量を計算
 */
const calcChangeParams = (data: Props) => {
  // 変化量を計算
  shopCostChangeDataList.value = getShopCostChangeData({
    cost: data.cost,
    wallet: useWallet().wallet.value,
    animateWallet: useAnimateWallet().wallet.value,
  });
};
/**
 * メッセージ種別を取得
 */
const getMessageType = (data: Props): MessageType => {
  if (!isCanBuyCost({
    cost: data.cost,
    wallet: useWallet().wallet.value,
    animateWallet: useAnimateWallet().wallet.value,
  })) {
    if (isAnimatePlatform()) {
      return 'lessGeso';
    }
    return 'less';
  }
  if (data.cost.purchasedGem > 0) {
    return 'gem';
  }
  if (data.cost.gesoCoin > 0) {
    if (data.cost.freeGem > 0) {
      return 'gesoAndGem';
    } else {
      return 'geso';
    }
  }
  return 'gem';
};
</script>
